import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Form.css";
import { ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "../../firebase";
import { setDB } from "../../dbactions";
import { signInAnonymously } from "firebase/auth";

function SunrinHackathonPage() {
  const [formData, setFormData] = useState({
    name: "",
    studentId: "",
    phoneNumber: "",
    teamName: "",
    schoolEmail: "",
    tshirtSize: "",
    role: "",
    category: "",
  });
  const [portfolio, setPortfolio] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // eslint-disable-next-line
  const setDate = () => {
    const now = new Date();
    // console.log(typeof now.toString());
    setFormData({ ...formData, date: now.toString() });
  };
  // eslint-disable-next-line
  const handleFileChange = (e) => {
    setPortfolio(e.target.files[0]);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    // console.log(portfolio);
    e.preventDefault();
    // console.log(formData);
    if (
      formData.name &&
      formData.phoneNumber &&
      formData.role &&
      formData.schoolEmail &&
      formData.studentId &&
      formData.teamName &&
      formData.tshirtSize &&
      formData.category &&
      portfolio != null
    ) {
      signInAnonymously(auth)
        .then((credential) => {
          const user = credential.user;
          console.log(user);
          console.log("익명 로그인 성공");
          const fileRef = ref(
            storage,
            `${formData.teamName}/${formData.studentId}${formData.name}/${portfolio.name}`
          );
          uploadBytes(fileRef, portfolio).then((snapshot) => {
            // console.log("Uploaded a file!");
          });

          // console.log(formData);
          setDB(formData);
          navigate("/submit");
        })
        .catch((err) => {
          console.error(err.code, err.message);
        });
    }
  };

  return (
    <div>
      <div className="container">
        <h1>10TH</h1>
        <h1>SUNRIN</h1>
        <h1>HACKATHON</h1>
        <h1>2024</h1>
        {/* <div className="notsubmit">
          지금은 제출기한이 아닙니다.
          <br />
          제출기한 : 6월 10일 ~ 6월 14일 23시 59분
        </div> */}
        <p className="notion">
          * 파일은 하나로 압축하여 제출해주세요.
          <br />
          최대 파일 크기는 10GB입니다.
          <br />
          여러번 제출시 가장 마지막으로 제출한 정보로 접수합니다
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group-inline">
            <div className="form-group">
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="이름"
              />
            </div>
            <div className="form-group">
              <input
                required
                type="text"
                name="studentId"
                value={formData.studentId}
                onChange={handleChange}
                placeholder="학번"
              />
            </div>
          </div>
          <div className="form-group">
            <input
              required
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="전화번호"
            />
          </div>
          <div className="form-group">
            <input
              required
              type="text"
              name="teamName"
              value={formData.teamName}
              onChange={handleChange}
              placeholder="팀 이름"
            />
          </div>
          <div className="form-group">
            <input
              required
              type="email"
              name="schoolEmail"
              value={formData.schoolEmail}
              onChange={handleChange}
              placeholder="학교 이메일"
            />
          </div>
          <div className="form-group">
            <select
              required
              name="role"
              value={formData.role}
              onChange={handleChange}
              placeholder="직책"
            >
              <option option value="직책">
                직책
              </option>
              <option value="팀장">팀장</option>
              <option value="개발자">개발자</option>
              <option value="디자이너">디자이너</option>
            </select>
          </div>
          <div className="form-group">
            <select
              required
              name="category"
              value={formData.category}
              onChange={handleChange}
              placeholder="참가 분야"
            >
              <option value="">참가분야</option>
              <option value="game">게임</option>
              <option value="life">생활</option>
            </select>
          </div>
          <div className="form-group">
            <select
              required
              name="tshirtSize"
              value={formData.tshirtSize}
              onChange={handleChange}
              placeholder="티셔츠 사이즈"
            >
              <option value="">사이즈 선택</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="2XL">2XL</option>
              <option value="3XL">3XL</option>
            </select>
          </div>
          <div className="form-group-inline2">
            <div className="form-group">
              <label className="custom-file-upload">
                <input
                  type="file"
                  name="portfolio"
                  onChange={handleFileChange}
                  placeholder="포트폴리오"
                  required
                />
                <span style={{ overflow: "hidden" }} className="span-portfolio">
                  {portfolio == null ? "포트폴리오" : portfolio.name}
                </span>
              </label>
            </div>
            <button type="submit" onClick={setDate}>
              제출하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SunrinHackathonPage;
