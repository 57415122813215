import React, { useRef } from "react";
import Header2 from "../components/Header2";
import Carousel from "../components/Carousel/Carousel";
import Footer from "../components/Footer";
import GuestBook from "../components/GuestBook/GuestBook";

function FormPage() {
  const introRef = useRef(null);
  const scheduleRef = useRef(null);
  const faqRef = useRef(null);
  const checkRef = useRef(null);

  return (
    <div>
      <Header2
        introRef={introRef}
        scheduleRef={scheduleRef}
        faqRef={faqRef}
        checkRef={checkRef}
      />
      <main>
        <GuestBook />
        <Carousel />
      </main>
      <Footer />
    </div>
  );
}

export default FormPage;
