import React from "react";
import "./Carousel.css";
import image1 from "../../assets/footer_img.svg";
import image2 from "../../assets/footer_img.svg";
import image3 from "../../assets/footer_img.svg";

function Carousel() {
  return (
      <div className="logos">
        <div className="logos-slide">
          <img src={image1} alt="Logo 1" />
          <img src={image2} alt="Logo 2" />
          <img src={image3} alt="Logo 3" />
          <img src={image1} alt="Logo 1" />
          <img src={image2} alt="Logo 2" />
          <img src={image3} alt="Logo 3" />
        </div>
      </div>
  );
}

export default Carousel;
