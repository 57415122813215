import "./Apply.css";

function Apply() {
  return (
    <div>
      <div className="apply-container">
        <h1>10TH</h1>
        <h1>SUNRIN</h1>
        <h1>HACKATHON</h1>
        <h1>2024</h1>
        <div className="form">
          {/* <div className="apply">
              <div className="link-apply">
                <a href="https://form.sunrin.io/student" target="_blank" rel="noopener noreferrer">재학생 참가신청</a>
              </div>
              <div className="gradu">
                <div className="link-apply">
                  <a href="https://graduate.sunrin.io/application" target="_blank" rel="noopener noreferrer">졸업생 참가신청</a>
            </div>
            <div className="link-apply">
                <a href="https://graduate.sunrin.io/mentoring" target="_blank" rel="noopener noreferrer">졸업생 멘토신청</a>
            </div>
          </div></div> */}
          <h2 style={{ margin: "50px" }}>지금은 지원기간이 아닙니다.</h2>
        </div>
      </div>
    </div>
  );
}

export default Apply;
