import React, { useState } from "react";
import "./FAQ.css";
import arrowOpen from "../../assets/arrow_black.svg";
import arrowClose from "../../assets/arrow.svg";

const FAQ = () => {
  const questions = [
    {
      question: "누구나 지원 가능한가요?",
      answer: "네, 선린인터넷고등학교 재학생이라면 누구나 지원 가능합니다.",
    },
    {
      question: "어떤 직군들이 있나요?",
      answer:
        "개발자, 디자이너, 팀장의 직군으로 참여 가능합니다. 팀마다 한명씩 팀장을 정해주세요! 팀장은 팀장으로 접수하시면 됩니다.",
    },
    {
      question: "포트폴리오가 필요한가요?",
      answer:
        "지원을 하기위해선 필수로 필요합니다! 본선에 진출할 팀을 선정하는데 중요한 기준이 됩니다.",
    },
    {
      question: "지원 방법이 궁금해요",
      answer: "홈페이지에서 지원서를 작성해주세요.",
    },
    {
      question: "사용하는 장비는 개인이 가져와야하나요?",
      answer: "네 사용하실 장비에 대해서는 개인지참 해주시면 됩니다.",
    },
    {
      question: "패널티 팀이 뭔가요?",
      answer: "접수 당시에 학번/이름/팀명 등을 잘못 기재하거나, 팀장이 없는 경우 또는 팀원 중 제출기한을 지키지 못한 사람이 있는 경우 패널티 팀으로 지정됩니다.",
    },
    {
      question: "외부 에셋은 사용 가능한가요?",
      answer: "무료로 사용 가능한 에셋은 사용 가능합니다. 다만 유료 에셋은 사용 불가합니다.",
    },
    {
      question: "생성형 AI의 사용 제한이 있나요?",
      answer: "따로 제한되어 있지는 않습니다.",
    },
  ];

  const [openIndexes, setOpenIndexes] = useState(
    Array(questions.length).fill(false)
  );

  const toggleAnswer = (index) => {
    setOpenIndexes((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="faq-container">
      <h1>자주 묻는 질문</h1>
      {questions.map((item, index) => (
        <div
          key={index}
          className={`faq-item ${openIndexes[index] ? "open" : ""}`}
          onClick={() => toggleAnswer(index)}
        >
          <div className="faq-question">
            <span className="question-text">{item.question}</span>
            <img
              src={openIndexes[index] ? arrowOpen : arrowClose}
              alt="Toggle"
              className="question-icon"
            />
          </div>
          {openIndexes[index] && (
            <div className="faq-answer">{item.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
