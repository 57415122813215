import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header2.css";
import logo from "../assets/logo.svg";

function Header2() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth > 768) {
  //       // 768px 이상이 되면
  //       closeMenu(true); // 메뉴를 닫고 헤더 색상을 리셋합니다.
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // 컴포넌트 언마운트 시 이벤트 리스너 제거
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const MenuOpen = () => {
  //   const menu = document.querySelector(".menu2");
  //   const menu_open = document.querySelector(".menu_open2");
  //   const header = document.querySelector(".header2");

  //   if (menu_open.innerText === "Menu") {
  //     menu_open.innerText = "Close";
  //     menu.style.height = "80vh";
  //     header.classList.add("menu-open");
  //   } else {
  //     menu_open.innerText = "Menu";
  //     menu.style.height = "0px";
  //     header.classList.remove("menu-open");
  //   }
  // };

  // const closeMenu = (forceClose = false) => {
  //   const menu = document.querySelector(".menu2");
  //   const menu_open = document.querySelector(".menu_open2");
  //   const header = document.querySelector(".header2");

  //   if (forceClose || menu_open.innerText === "Close") {
  //     menu_open.innerText = "Menu";
  //     menu.style.height = "0px";
  //     header.classList.remove("menu-open");
  //   }
  // };

  // const handleNavigation = (sectionId) => {
  //   navigate(`/?section=${sectionId}`);
  //   closeMenu();
  // };

  return (
    <header className="header2">
      <div
        className="logo2"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="로고" />
      </div>
      {/* <div className="menu_open2" onClick={MenuOpen}>
        Menu
      </div> */}
      <nav className="menu2">
        {/* <h1>MENU</h1>
        <div
          onClick={() => handleNavigation("introduction")}
          className="menu-hack"
        >
          해커톤소개
        </div>
        <div onClick={() => handleNavigation("schedule")} className="menu-sche">
          해커톤일정
        </div>
        <div onClick={() => handleNavigation("faq")} className="menu-faq">
          자주묻는질문
        </div> */}
        {/* <div
          className="checklink"
          onClick={() => {
            navigate("/check");
          }}
          style={{ cursor: "pointer" }}
        >
          접수내역확인
        </div> */}
      </nav>
    </header>
  );
}

export default Header2;
