import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Form from "./pages/Form";
import Submit from "./pages/Submit";
// import SubmitCheck from "./pages/SubmitCheck";
import Agree from "./pages/Agree";
import Apply from "./pages/Apply";
import GuestBook from "./pages/GuestBook";

function App() {
  return (
    <HashRouter>
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/form" element={<Form />} />
          <Route path="/submit" element={<Submit />} />
          {/* <Route path="/check" element={<SubmitCheck />} /> */}
          <Route path="/agree" element={<Agree />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/guestbook" element={<GuestBook />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
