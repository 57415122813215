import React from 'react';
import '../App.css';
import Header2 from '../components/Header2';
import Submit from '../components/Submit/Submit';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel/Carousel';

function App() {
  return (
    <div className="App">
      <Header2 />
      <main>
        <Submit/>
        <Carousel />
      </main>
      <Footer />
    </div>
  );
}

export default App;
