import React from 'react';
import './Footer.css';
import logo from '../assets/footer_logo.svg';

function Footer() {
  return (
    <div className="footer-component">
      <div className="footer-top">
        <span className="footer-copyright">
          <span className="short-text">©2024 선린인터넷고등학교.</span>
          <span className="full-text">©2024 선린인터넷고등학교. ALL RIGHTS RESERVED.</span>
        </span>
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="footer-bottom">
        <a href="https://www.instagram.com/sunrin_hackathon/" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
    </div>
  );
}

export default Footer;
