import { ref, set } from "firebase/database";
import { db } from "./firebase";

export const setDB = (formData) => {
  set(
    ref(
      db,
      "신청자목록/" +
        formData.teamName +
        "/" +
        formData.studentId +
        formData.name
    ),
    formData
  );
};
