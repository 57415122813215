import React from "react";
import "./Introduction.css";
import hackathonimg from "../../assets/hackathon.png";

function Introduction() {
  return (
    <section className="introduction">
      <div className="intro-text">
        <h1>해커톤 소개</h1>
        <p>
          올해를 맞아 제 10회를 맞이하는 
          <br />선린 해커톤은 제한 시간 내에
          <br />소프트웨어를 개발하는 대회입니다.
          <br />정해진 주제에 맞춰 하루 만에 
          <br />앱이나 게임을 개발 해보세요!
          <br />창의적인 아이디어를 발휘하고 
          <br />팀과 함께 협력하여
          <br />멋진 프로젝트를 완성해보세요.
        </p>
      </div>
      <img src={hackathonimg} alt="Hackathon Introduction" />
    </section>
  );
}

export default Introduction;
