import React from "react";
import "./Submit.css";


function Submit() {
  return (
    <div className="form-done-container">
      
      <h2>THANK YOU</h2>
      <div>
      지원이 완료되었습니다!
      </div>
      <div>
      참가자 발표는 추후 개별 연락 예정입니다.
      </div>
      
    </div>
  );
}

export default Submit;
