import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Notice.css";

import slide1 from "../../assets/pdf/slide1.png";
import slide2 from "../../assets/pdf/slide2.png";
import slide3 from "../../assets/pdf/slide3.png";
import slide4 from "../../assets/pdf/slide4.png";
import slide5 from "../../assets/pdf/slide5.png";
import slide6 from "../../assets/pdf/slide6.png";
import slide7 from "../../assets/pdf/slide7.png";
import slide8 from "../../assets/pdf/slide8.png";
import slide9 from "../../assets/pdf/slide9.png";
import slide10 from "../../assets/pdf/slide10.png";
import slide11 from "../../assets/pdf/slide11.png";
import slide12 from "../../assets/pdf/slide12.png";
import slide13 from "../../assets/pdf/slide13.png";
import slide14 from "../../assets/pdf/slide14.png";
import slide15 from "../../assets/pdf/slide15.png";
import arrow from "../../assets/arrow.svg";
import { EffectCoverflow, Navigation } from "swiper/modules";

export default function Notice() {
  const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
    slide13,
    slide14,
    slide15,
  ];
  return (
    <div className="notice_page">
      <h1>해커톤 OT 및 멘토링 안내</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 200,
          modifier: 2.5,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slides[0]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[1]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[2]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[3]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[4]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[5]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[6]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[7]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[8]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[9]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[10]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[11]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[12]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[13]} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slides[14]} alt="img" />
        </SwiperSlide>
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <img src={arrow} style={{ transform: "rotateY(180deg)" }} alt="" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <img src={arrow} alt="" />
          </div>
        </div>
      </Swiper>
    </div>
  );
}
