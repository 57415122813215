import React from "react";
import "./Schedule.css";

const Schedule = () => {
  return (
    <div className="schedule-container">
      <h1>해커톤 일정</h1>
      <div className="schedule-item">
        <div className="schedule-event">지원 시작</div>
        <div className="schedule-date">6월 10일</div>
      </div>
      <div className="schedule-item">
        <div className="schedule-event">지원 마감</div>
        <div className="schedule-date">6월 14일 23시 59분</div>
      </div>
      <div className="schedule-item">
        <div className="schedule-event">참가자 발표</div>
        <div className="schedule-date">추후 연락 예정</div>
      </div>
      <div className="schedule-item">
        <div className="schedule-event">오리엔테이션</div>
        <div className="schedule-date">7월 15일</div>
      </div>
      <div className="schedule-item">
        <div className="schedule-event">선린해커톤 개최</div>
        <div className="schedule-date">7월 19일</div>
      </div>
      <div className="schedule-item">
        <div className="schedule-event">심사 및 결과발표</div>
        <div className="schedule-date">7월 20일</div>
      </div>
    </div>
  );
};

export default Schedule;
