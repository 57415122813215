import React from "react";
import { useNavigate } from "react-router-dom";
import "./Agree.css"; // 이 부분은 별도 CSS 파일을 사용하므로 여전히 필요

function Agree() {
  const navigate = useNavigate();

  const HandleToForm = () => {
    if (document.querySelector(".agree_check").checked) navigate("/form");
  };

  return (
    <div className="agree-container">
      <h2>안내사항</h2>
      <div className="agree-box">
        <div>
          1. 해커톤 본선 진출팀은 7월 9일 화요일 방과후에 진행하는 사전
          오리엔테이션에 반드시 참여해야 합니다.
        </div>
        <div>
          2. 제 10회 해커톤의 수상 내역은 생활기록부 수상 경력란에 기재되지
          않으며, 활동 내용 및 작품의 수준에 따라 생활기록부
          창의적체험활동상황에 기재될 수 있습니다.
        </div>
        <div>
          3. 포스터 및 홈페이지, 해커톤 공식 페이스북과 인스타그램 계정에 안내된
          모든 유의사항을 숙지하였으며 해당 사항을 숙지하지 못하여 발생하는
          불이익은 본인의 책임입니다.
        </div>
      </div>
      <div className="agree">
        <input
          type="checkbox"
          name="agree"
          value="agree"
          className="agree_check"
          id="agree"
        />
        <label htmlFor="agree" className="custom-checkbox"></label>
        <label htmlFor="agree">확인했습니다.</label>
      </div>
      <button className="agree_to_form" onClick={HandleToForm}>
        다음
      </button>
    </div>
  );
}

export default Agree;
