import React from "react";
import "./Header.css";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

function Header({ introRef, scheduleRef, noticeRef, faqRef }) {
  const MenuOpen = () => {
    const menu = document.querySelector(".menu");
    const menu_open = document.querySelector(".menu_open");
    const header = document.querySelector(".header");
    if (menu_open.innerText === "Menu") {
      menu_open.innerText = "Close";
      menu.classList.add("menu_opened");
      // menu.style.height = "80vh";
      header.classList.add("menu-open");
    } else {
      menu_open.innerText = "Menu";
      // menu.style.height = "0px";
      menu.classList.remove("menu_opened");
      header.classList.remove("menu-open");
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };

  const closeMenu = () => {
    const menu = document.querySelector(".menu");
    const menu_open = document.querySelector(".menu_open");
    const header = document.querySelector(".header");

    menu.classList.add("menu-no-transition");

    menu_open.innerText = "Menu";
    menu.classList.remove("menu_opened");
    // menu.style.height = "0px";
    header.classList.remove("menu-open");

    setTimeout(() => {
      menu.classList.remove("menu-no-transition");
    }, 0);
  };
  const navigate = useNavigate();

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="로고" onClick={() => navigate("/")} />
      </div>
      <div className="menu_open" onClick={MenuOpen}>
        Menu
      </div>
      <nav className="menu">
        <h1>MENU</h1>
        {/* <a href="#introduction" onClick={() => scrollToSection(introRef)}>
          해커톤소개
        </a>
        <a href="#schedule" onClick={() => scrollToSection(scheduleRef)}>
          해커톤일정
        </a>
        <a href="#faq" onClick={() => scrollToSection(faqRef)}>
          자주묻는질문
        </a> */}
        <a
          href="https://ajar-cemetery-197.notion.site/fe76888c35b34ea1a88981b236ceaa21?pvs=4"
          target="_blank"
          rel="noreferrer"
          className="span"
        >
          선린해커톤 후원
        </a>
        <span onClick={() => scrollToSection(introRef)}>해커톤소개</span>
        <span onClick={() => scrollToSection(scheduleRef)}>해커톤일정</span>
        <span onClick={() => scrollToSection(noticeRef)}>해커톤OT</span>
        <span onClick={() => scrollToSection(faqRef)}>자주묻는질문</span>
        {/* <a href="/form">접수하기</a> */}
      </nav>
    </header>
  );
}

export default Header;
