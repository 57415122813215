import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import CountdownTimer from "../components/Main/CountdownTimer";
import Introduction from "../components/Main/Introduction";
import Schedule from "../components/Main/Schedule";
import FAQ from "../components/Main/FAQ";
import Staff from "../components/Main/Staff";
import Carousel from "../components/Carousel/Carousel";
import Footer from "../components/Footer";
import Notice from "../components/Main/Notice";

function Main() {
  const introRef = useRef(null);
  const scheduleRef = useRef(null);
  const noticeRef = useRef(null);
  const faqRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");

    if (section) {
      let ref;
      switch (section) {
        case "introduction":
          ref = introRef;
          break;
        case "schedule":
          ref = scheduleRef;
          break;
        case "notice":
          ref = noticeRef;
          break;
        case "faq":
          ref = faqRef;
          break;
        default:
          ref = null;
      }

      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="App">
      <Header
        introRef={introRef}
        scheduleRef={scheduleRef}
        noticeRef={noticeRef}
        faqRef={faqRef}
      />
      <main>
        <CountdownTimer />
        <section ref={introRef}>
          <Introduction />
        </section>
        <section ref={scheduleRef}>
          <Schedule />
        </section>
        <section ref={noticeRef}>
          <Notice />
        </section>
        <section ref={faqRef}>
          <FAQ />
        </section>
        <Staff />
        <Carousel />
      </main>
      <Footer />
    </div>
  );
}

export default Main;
