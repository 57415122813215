import React, { useState, useEffect } from "react";
import "./CountdownTimer.css";
import countdown from "../../assets/countdown.svg";
import arrow_white_down from "../../assets/arrow_down_bold.svg";
import { Link } from "react-router-dom";

function CountdownTimer() {
  const calculateTimeLeft = () => {
    // Set target time in KST (UTC+9)
    const targetDate = new Date("2024-07-20T07:00:00+09:00");
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (value) => {
    return String(value).padStart(2, "0");
  };

  return (
    <div>
      <div className="content">
        <div className="title">
          <h1>10TH</h1>
          <h1>SUNRIN</h1>
          <h1>HACKATHON</h1>
          <h1>2024</h1>
        </div>
        <div className="main-box">
          <div className="button-container">
            <div className="countdown">
              <img src={countdown} alt="coming" />
              <div className="countdowntext">
                {Object.keys(timeLeft).length ? (
                  <div>
                    {formatTime(timeLeft.days)}:{formatTime(timeLeft.hours)}:
                    {formatTime(timeLeft.minutes)}:
                    {formatTime(timeLeft.seconds)}
                  </div>
                ) : (
                  <div>Time's up!</div>
                )}
                <div>제출 마감까지</div>
              </div>
            </div>
          </div>
          <div className="link-form">
            <Link to="/guestbook">방명록</Link>
          </div>
        </div>
        <img src={arrow_white_down} alt="" className="white_arrow_down" />
      </div>
    </div>
  );
}

export default CountdownTimer;
