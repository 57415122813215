import React from "react";
import "./Staff.css";
import { Link } from "react-router-dom";

function Staff() {
  const prepareNames = [
    "박준영",
    "이지우",
    "이혜린",
    "하승호",
    "김송연",
    "조형서",
    "오유성",
    "이기훈",
    "이나린",
    "이하람",
    "송혜인",
    "유재희",
    "윤찬호",
    "김태경",
    "이정우",
    "김범근",
  ];

  const developNames = ["김송연", "이기훈"];

  const designNames = ["오지후", "안재민", "김대현", "강건호", "이승철"];

  const renderNames = (names) => {
    return names.map((name, index) => (
      <span key={index} className="staff-name">
        {name}
        {(index + 1) % 5 === 0 ? <br /> : " "}
      </span>
    ));
  };

  return (
    <div className="staff-container">
      <h2 className="staff-title">운영진</h2>
      <div className="staff-content">
        <div className="staff-section">
          <h4 className="staff-heading">준비</h4>
          <div className="staff-names">{renderNames(prepareNames)}</div>
        </div>
        <div className="staff-section">
          <h4 className="staff-heading">개발</h4>
          <div className="staff-names">{renderNames(developNames)}</div>
        </div>
        <div className="staff-section">
          <h4 className="staff-heading">디자인</h4>
          <div className="staff-names">{renderNames(designNames)}</div>
        </div>
      </div>
      <div
        className="link-form"
        style={{
          margin: "10px auto",
          marginTop: "100px",
          width: "170px",
          height: "70px",
          lineHeight: "70px",
        }}
      >
        <Link to="/apply" style={{ lineHeight: "70px" }}>
          APPLY
        </Link>
      </div>
    </div>
  );
}

export default Staff;
