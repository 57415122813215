import { ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { onValue } from "firebase/database";
import "./GuestBook.css";

const WriteMemo = ({ setIsOpened }) => {
  const [memo, setMemo] = useState({
    name: "",
    text: "",
    number: 0,
  });
  const [memolist, setmemoList] = useState([]);
  useEffect(() => {
    const Ref = ref(db, "방명록");
    onValue(Ref, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      setmemoList(data);
    });
  }, []);
  const onchangeText = (e) => {
    setMemo({ ...memo, text: e.target.value });
  };
  const onChangeName = (e) => {
    setMemo({ ...memo, name: e.target.value });
  };
  const onChangeNumber = (e) => {
    setMemo({ ...memo, number: e.target.value });
  };
  const handleSubmit = (e) => {
    // console.log(portfolio);
    e.preventDefault();
    // console.log(formData);
    if (memo.name && memo.text && memo.number) {
      set(ref(db, "방명록"), memolist.concat(memo));
      setIsOpened(false);
    }
  };
  return (
    <div className="x">
      <h2>방명록 작성하기</h2>
      <textarea onChange={onchangeText} placeholder="내용..."></textarea>
      <div className="bellow_text">
        <input type="text" onChange={onChangeNumber} placeholder="기수..." />
        <input type="text" onChange={onChangeName} placeholder="이름..." />
        <button
          onClick={() => {
            setIsOpened(false);
          }}
        >
          취소
        </button>
        <button onClick={handleSubmit}>등록하기</button>
      </div>
    </div>
  );
};

const GuestBook = () => {
  const [memolist, setmemoList] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    const Ref = ref(db, "방명록");
    onValue(Ref, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      setmemoList(data);
    });
  }, []);
  return (
    <div className="guestbook">
      {isOpened && <WriteMemo setIsOpened={setIsOpened} />}
      <div className="a">
        <div className="aa">방명록</div>
        <div
          className="ab"
          onClick={() => {
            setIsOpened(true);
          }}
        >
          작성하기
        </div>
      </div>
      <div className="A">
        <div>제 10회 해커톤 어떠셨나요?</div>
        <div>
          저희 운영진이 10년째 이어져와 올해 제 10회를 맞은 선린톤인만큼
          최대규모로 모두의 안전과 재미를 위해 열심히 준비했습니다!
        </div>
        <div>해커톤 어떻게 잘 즐기셨나요?!</div>
        <div>후기, 방명록 어떤것이든 좋으니 많은 작성 부탁드려요!</div>
      </div>
      <div className="b">
        {memolist.map((memo) => (
          <div className="ba">
            <div className="baa">{memo.text}</div>
            <div className="bab">{memo.name}</div>
            <div className="bac">{memo.number}기</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuestBook;
